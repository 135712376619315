/* You can add global styles to this file, and also import other style files */
// @import '@swimlane/ngx-datatable/index.css';
// @import '@swimlane/ngx-datatable/themes/material.scss';
// @import '@swimlane/ngx-datatable/assets/icons.css';
@import '../node_modules/@swimlane/ngx-datatable/index.css';
@import '../node_modules/@swimlane/ngx-datatable/themes/material.scss';
@import '../node_modules/@swimlane/ngx-datatable/assets/icons.css';

//== App
@import './app/shared/styles/app';
@import '@angular/material/prebuilt-themes/indigo-pink.css';

mat-form-field {
  width: 100%;
}

:root {
  --color-iua: #da291c;
  --color-success: #21b664;
}

body.swal2-toast-shown > .swal2-container.swal2-top-end,
body.swal2-toast-shown > .swal2-container.swal2-top-right {
  top: 70px !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.12) !important;
}

a[href="https://froala.com/wysiwyg-editor"], a[href="https://www.froala.com/wysiwyg-editor?k=u"]
{
  display: none !important;
  position: absolute;
  top: -99999999px;
}

.color-iua {
  color: #da291c;
}
.color-success {
  color: #21b664;
}
a {
  color: #ef1000;
}

.background-color-iua {
  background-color: #da291c;
}

.btn.btn-primary {
  background-color: #da291c;
  border-color: #b32016;
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  background-color: #b32016;
  border-color: #da291c;
}

.m-badge.m-badge--danger {
  background-color: #b32016;
  color: #fff;
}

#block-me {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 999;
  opacity: 0;
  display: none;
}

.mat-mdc-input-element:disabled {
  color: rgba(0, 0, 0, 0.8);
}

.flaticon-delete-2 {
  color: #da0000;
}

.list-group-item {
  cursor: pointer;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #da291c;
  border-color: #b11f14;
}

@media (max-width: 767px) {
  .min-width-600 {
    min-width: 600px;
  }
  .min-width-650 {
    min-width: 650px;
  }
  .min-width-700 {
    min-width: 700px;
  }
  .min-width-750 {
    min-width: 750px;
  }
}

@media (min-width: 1025px) {
  .m-footer--push.m-aside-left--enabled:not(.m-footer--fixed) .m-aside-right,
  .m-footer--push.m-aside-left--enabled:not(.m-footer--fixed) .m-wrapper {
    margin-bottom: 0px !important;
  }
}
