.ngx-datatable.material {
	background: #fff;
	-webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12),
		0 1px 2px 0 rgba(0, 0, 0, 0.24);
	-moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12),
		0 1px 2px 0 rgba(0, 0, 0, 0.24);
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);

	&.striped {
		.datatable-row-odd {
			background: #e5e9ee;
		}
	}

	&.single-selection,
	&.multi-selection,
	&.multi-click-selection {
		.datatable-body-row {
			&.active,
			&.active .datatable-row-group {
				background-color: #304ffe;
				color: #fff;
			}

			&.active:hover,
			&.active:hover .datatable-row-group {
				background-color: #193ae4;
				color: #fff;
			}

			&.active:focus,
			&.active:focus .datatable-row-group {
				background-color: #2041ef;
				color: #fff;
			}
		}
	}

	&:not(.cell-selection) {
		.datatable-body-row {
			&:hover,
			&:hover .datatable-row-group {
				background-color: darken(#e5e9ee, 2%);
				transition-property: background;
				transition-duration: .3s;
				transition-timing-function: linear;
			}

			&:focus,
			&:focus .datatable-row-group {
				background-color: #e5e9ee;
			}
		}
	}

	&.cell-selection {
		.datatable-body-cell {
			&:hover,
			&:hover .datatable-row-group {
				background-color: darken(#e5e9ee, 2%);
				transition-property: background;
				transition-duration: .3s;
				transition-timing-function: linear;
			}

			&:focus,
			&:focus .datatable-row-group {
				background-color: #e5e9ee;
			}

			&.active,
			&.active .datatable-row-group {
				background-color: #304ffe;
				color: #fff;
			}

			&.active:hover,
			&.active:hover .datatable-row-group {
				background-color: #193ae4;
				color: #fff;
			}

			&.active:focus,
			&.active:focus .datatable-row-group {
				background-color: #2041ef;
				color: #fff;
			}
		}
	}

	/**
	 * Shared Styles
	 */
	.empty-row {
		height: 50px;
		text-align: left;
		padding: .5rem 1.2rem;
		vertical-align: top;
		border-top: 0;
	}

	.loading-row {
		text-align: left;
		padding: .5rem 1.2rem;
		vertical-align: top;
		border-top: 0;
	}

	/**
	 * Global Row Styles
	 */
	.datatable-header,
	.datatable-body {
		.datatable-row-left {
			background-color: #fff;
			background-position: 100% 0;
			background-repeat: repeat-y;
			background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAABCAYAAAD5PA/NAAAAFklEQVQIHWPSkNeSBmJhTQVtbiDNCgASagIIuJX8OgAAAABJRU5ErkJggg==);
		}

		.datatable-row-right {
			background-position: 0 0;
			background-color: #fff;
			background-repeat: repeat-y;
			background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAABCAYAAAD5PA/NAAAAFklEQVQI12PQkNdi1VTQ5gbSwkAsDQARLAIGtOSFUAAAAABJRU5ErkJggg==);
		}
	}

	/**
	 * Header Styles
	 */
	.datatable-header {
		border-bottom: 1px solid #d7e1ed;

		.datatable-header-cell {
			text-align: left;
			padding: .5rem 1.2rem;
			font-weight: 400;
			color: #757575;
			vertical-align: bottom;

			.datatable-header-cell-wrapper {
				position: relative;
			}

			&.longpress {
				.draggable::after {
					transition: transform 400ms ease, opacity 400ms ease;
					opacity: .5;
					transform: scale(1);
				}
			}

			.draggable::after {
				content: " ";
				position: absolute;
				top: 50%;
				left: 50%;
				margin: -30px 0 0 -30px;
				height: 60px;
				width: 60px;
				background: darken(#e5e9ee, 2%);
				border-radius: 100%;
				opacity: 1;
				filter: none;
				transform: scale(0);
				z-index: 9999;
				pointer-events: none;
			}

			&.dragging {
				.resize-handle {
					border-right: none;
				}
			}
		}

		.resize-handle {
			border-right: solid 1px darken(#e5e9ee, 2%);
		}
	}

	/**
	 * Body Styles
	 */
	.datatable-row-wrapper {
		border-bottom: 1px solid #d7e1ed;
		&:last-child {
			border: none;
		}
	}
	.datatable-body {
		.datatable-row-detail {
			background: #f5f5f5;
			padding: 10px;
		}

		.datatable-body-row {
			.datatable-body-cell {
				text-align: left;
				padding: .5rem 1.2rem;
				vertical-align: top;
				border-top: 0;

				-webkit-transition: width 0.3s ease;
				transition: width 0.3s ease;

				// cell active class
				// &.active {
				//  background: #0829e0
				// }
			}
		}

		.progress-linear {
			display: block;
			position: relative;
			width: 100%;
			height: 5px;
			padding: 0;
			margin: 0;
			position: absolute;

			.container {
				display: block;
				position: relative;
				overflow: hidden;
				width: 100%;
				height: 5px;
				-webkit-transform: translate(0, 0) scale(1, 1);
				transform: translate(0, 0) scale(1, 1);
				background-color: rgb(170, 209, 249);

				.bar {
					transition: all .2s linear;
					-webkit-animation: query .8s infinite cubic-bezier(.39, .575, .565, 1);
					animation: query .8s infinite cubic-bezier(.39, .575, .565, 1);

					transition: -webkit-transform .2s linear;
					transition: transform .2s linear;
					background-color: rgb(16, 108, 200);

					position: absolute;
					left: 0;
					top: 0;
					bottom: 0;
					width: 100%;
					height: 5px;
				}
			}
		}
	}

	/**
	 * Footer Styles
	 */
	.datatable-footer {
		border-top: 1px solid #d7e1ed;
		font-size: 16px;

		.page-count {
			line-height: 50px;
			height: 50px;
			padding: 0 1.2rem;
		}

		.datatable-pager {
			margin: 0 10px;
			//line-height: 50px;

			li {
				vertical-align: middle;

				&.disabled a {
					color: rgba(0, 0, 0, 0.26) !important;
					background-color: transparent !important;
				}

				&.active a {
					background-color: rgba(158, 158, 158, 0.2);
					font-weight: bold;
				}
			}

			a {
				height: 22px;
				min-width: 24px;
				line-height: 22px;
				padding: 0 6px;
				border-radius: 3px;
				margin: 6px 3px;
				text-align: center;
				vertical-align: top;
				color: rgba(0, 0, 0, .54);
				text-decoration: none;
				vertical-align: bottom;

				&:hover {
					color: rgba(0, 0, 0, .75);
					background-color: rgba(158, 158, 158, 0.2);
				}
			}

			.datatable-icon-left,
			.datatable-icon-skip,
			.datatable-icon-right,
			.datatable-icon-prev {
				font-size: 20px;
				line-height: 20px;
				padding: 0 3px;
			}
		}
	}
}

/**
 * Checkboxes
**/
.datatable-checkbox {
	position: relative;
	margin: 0;
	cursor: pointer;
	vertical-align: middle;
	display: inline-block;
	box-sizing: border-box;
	padding: 10px 0;

	input[type='checkbox'] {
		position: relative;
		margin: 0 1rem 0 0;
		cursor: pointer;
		outline: none;

		&:before {
			-webkit-transition: all 0.3s ease-in-out;
			-moz-transition: all 0.3s ease-in-out;
			transition: all 0.3s ease-in-out;
			content: "";
			position: absolute;
			left: 0;
			z-index: 1;
			width: 1rem;
			height: 1rem;
			border: 2px solid #f2f2f2;
		}

		&:checked:before {
			-webkit-transform: rotate(-45deg);
			-moz-transform: rotate(-45deg);
			-ms-transform: rotate(-45deg);
			transform: rotate(-45deg);
			height: .5rem;
			border-color: #009688;
			border-top-style: none;
			border-right-style: none;
		}

		&:after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 1rem;
			height: 1rem;
			background: #fff;
			cursor: pointer;
		}
	}
}

/**
 * Progress bar animations
 */
@keyframes query {
	0% {
		opacity: 1;
		transform: translateX(35%) scale(.3, 1);
	}

	100% {
		opacity: 0;
		transform: translateX(-50%) scale(0, 1);
	}
}
